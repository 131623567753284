import fetchAllStatistics from '@lyra/core/api/fetchAllStatistics'
import { AllInstrumentStatisticsSchema } from '@lyra/core/api/types/public.all_statistics'
import useSWR from 'swr'

import { InstrumentType } from '../constants/instruments'
import { CurrencyId } from '../constants/tokens'
import {
  getListedOptionsMarketIds,
  getListedPerpsMarketIds,
  getListedSpotCollateralIds,
} from '../utils/markets'

type MarketStats = Record<
  InstrumentType,
  Record<CurrencyId, AllInstrumentStatisticsSchema | undefined>
>

const fetcher = async (): Promise<MarketStats> => {
  const { result: stats } = await fetchAllStatistics({})

  const options = getListedOptionsMarketIds().reduce(
    (dict, marketId) => ({
      ...dict,
      [marketId]: stats.find(
        (stat) =>
          stat.instrument_type === InstrumentType.Options &&
          stat.currency.toLowerCase() === marketId.toLowerCase()
      ),
    }),
    {} as MarketStats[InstrumentType.Options]
  )

  const perps = getListedPerpsMarketIds().reduce(
    (dict, marketId) => ({
      ...dict,
      [marketId]: stats.find(
        (stat) =>
          stat.instrument_type === InstrumentType.Perps &&
          stat.currency.toLowerCase() === marketId.toLowerCase()
      ),
    }),
    {} as MarketStats[InstrumentType.Perps]
  )

  const spot = getListedSpotCollateralIds().reduce(
    (dict, marketId) => ({
      ...dict,
      [marketId]: stats.find(
        (stat) =>
          stat.instrument_type === InstrumentType.Spot &&
          stat.currency.toLowerCase() === marketId.toLowerCase()
      ),
    }),
    {} as MarketStats[InstrumentType.Spot]
  )

  return {
    [InstrumentType.Options]: options,
    [InstrumentType.Perps]: perps,
    [InstrumentType.Spot]: spot,
  }
}

export default function useAllMarketStats() {
  return useSWR<MarketStats>(['AllMarketStats'], fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
  })
}
