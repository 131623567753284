import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicAllStatisticsParamsSchema,
  PublicAllStatisticsResponseSchema,
} from './types/public.all_statistics'

export default async function fetchAllStatistics(
  params: PublicAllStatisticsParamsSchema,
  options?: RequestOptions
) {
  return tryRequest<PublicAllStatisticsParamsSchema, PublicAllStatisticsResponseSchema>(
    '/public/all_statistics',
    params,
    options
  )
}
