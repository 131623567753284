import BodyText from '@lyra/core/components/BodyText'
import Card from '@lyra/core/components/Card'
import Carousel from '@lyra/core/components/Carousel'
import Heading from '@lyra/core/components/Heading'
import Responsive from '@lyra/core/components/Responsive'
import { GhostPost } from '@lyra/web/constants/ghost'
import { LANDING_PAGE_WIDTH } from '@lyra/web/constants/layout'
import Image from 'next/image'
import { useMemo } from 'react'
import { Stack, XStack, YStack } from 'tamagui'

type Props = {
  blogPosts: GhostPost[]
}

export const LandingBlogSection = ({ blogPosts }: Props) => {
  const blogs = useMemo(() => blogPosts.slice(0, 6), [blogPosts])
  return (
    <YStack
      borderTopColor="$hairline"
      borderTopWidth={1}
      paddingVertical="$10"
      width="100%"
      alignItems="center"
      paddingHorizontal="$3"
    >
      <YStack
        width="100%"
        $desktop={{
          maxWidth: LANDING_PAGE_WIDTH,
        }}
        gap="$6"
      >
        <YStack gap="$1">
          <Heading size="h2">Latest Posts</Heading>
          <BodyText size="lg" color="secondary">
            Updates and announcements from the Derive community
          </BodyText>
        </YStack>
        <Responsive
          width="100%"
          mobile={
            <Carousel width="100%">
              {blogs.map(({ url, feature_image, title }, idx) => {
                if (!url || !feature_image || !title) {
                  return
                }
                return (
                  <Card
                    onPress={() => window.open(url, '_blank')}
                    flexGrow={1}
                    key={idx}
                    height={230}
                    hoverStyle={{ cursor: 'pointer', backgroundColor: '$hoverBg' }}
                  >
                    <Image
                      unoptimized
                      src={feature_image}
                      loader={() => feature_image}
                      alt={feature_image}
                      width={306}
                      height={168}
                    />
                    <BodyText marginTop="$2" size="lg" color="primary">
                      {title}
                    </BodyText>
                  </Card>
                )
              })}
            </Carousel>
          }
          desktop={
            <XStack
              style={{
                display: 'grid',
                gap: '24px',
                gridTemplateColumns: 'repeat(3, 1fr)',
              }}
            >
              {blogs.map(({ url, feature_image }, idx) => {
                if (!url || !feature_image) {
                  return
                }
                return (
                  <Stack
                    onPress={() => window.open(url, '_blank')}
                    flexGrow={1}
                    flexShrink={1}
                    flexBasis={0}
                    key={idx}
                    cursor="pointer"
                    aspectRatio={306 / 168}
                    borderRadius="$1"
                    overflow="hidden"
                    opacity={0.75}
                    hoverStyle={{ opacity: 1.0 }}
                    borderColor="$hairline"
                    borderWidth={1}
                  >
                    <Image
                      unoptimized
                      src={feature_image}
                      loader={() => feature_image}
                      alt={feature_image}
                      fill
                    />
                  </Stack>
                )
              })}
            </XStack>
          }
        />
      </YStack>
    </YStack>
  )
}

export default LandingBlogSection
