import BodyText from '@lyra/core/components/BodyText'
import { Github, Twitter, Warpcast, Youtube } from '@lyra/core/components/Icon'
import Discord from '@lyra/core/components/Icon/DiscordIcon'
import Telegram from '@lyra/core/components/Icon/TelegramIcon'
import Link from '@lyra/core/components/Link'
import LinkIcon from '@lyra/core/components/LinkIcon'
import Responsive from '@lyra/core/components/Responsive'
import { LANDING_PAGE_WIDTH } from '@lyra/web/constants/layout'
import { PageId } from '@lyra/web/constants/pages'
import {
  baseUrl,
  BLOG_URL,
  CAREERS_URL,
  DELEGATE_PORTAL_URL,
  DISCORD_URL,
  DOCS_API_REFERENCE_URL,
  DOCS_FEES_URL,
  DOCS_URL,
  GITHUB_URL,
  GOVERNANCE_FORUMS_URL,
  GOVERNANCE_PORTAL_URL,
  HELP_URL,
  SNAPSHOT_URL,
  STATUS_URL,
  TELEGRAM_URL,
  TWITTER_URL,
  WARPCAST_URL,
  WHITEPAPER_URL,
  YOUTUBE_URL,
} from '@lyra/web/constants/urls'
import { getPagePath } from '@lyra/web/utils/pages'
import { saveAs } from 'file-saver'
import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'
import { Stack, YStack } from 'tamagui'

import NextLink from '../common/NextLink'

const ChatSupportLink = () => {
  const { show } = useIntercom()

  return (
    <Responsive
      desktop={<Link size="md" label="Chat Support" onPress={() => show()} target="_blank" />}
    />
  )
}

const LandingPageFooter = () => {
  const handleMediaKitDownload = useCallback(async () => {
    const fileUrl = `${baseUrl}/images/Derive_Media-Kit-Logos.zip`
    const response = await fetch(fileUrl)
    const blob = await response.blob()
    saveAs(blob, 'Derive_Media-Kit-Logos.zip')
  }, [])

  return (
    <Stack
      width="100%"
      alignItems="center"
      backgroundColor="$appBg"
      borderColor="$hairline"
      borderTopWidth={1}
      paddingHorizontal="$3"
    >
      <Stack width={LANDING_PAGE_WIDTH} maxWidth="100%" paddingVertical="$6">
        <Stack flexDirection="row" flexWrap="wrap" $mobile={{ gap: '$6' }}>
          <YStack
            gap="$2"
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            $mobile={{ flexBasis: 'auto' }}
          >
            <BodyText>About</BodyText>
            <Link size="md" label="Blog" href={BLOG_URL} target="_blank" />
            <NextLink size="md" label="Stats" href={getPagePath({ page: PageId.Stats })} />
            <Link size="md" label="FAQs" href={HELP_URL} target="_blank" />
            <Link size="md" label="Careers" href={CAREERS_URL} target="_blank" />
            <NextLink size="md" label="Partners" href={getPagePath({ page: PageId.Partners })} />
            <Link size="md" label="Media Kit" onPress={handleMediaKitDownload} target="_blank" />
            <Link size="md" label="Whitepaper" href={WHITEPAPER_URL} target="_blank" />
            <Link
              size="md"
              label="Terms of Use"
              href={getPagePath({ page: PageId.TermsOfUse })}
              target="_blank"
            />
            <Link
              size="md"
              label="Privacy Policy"
              href={getPagePath({ page: PageId.TermsOfUse })}
              target="_blank"
            />
          </YStack>
          <YStack
            gap="$2"
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            $mobile={{ flexBasis: 'auto' }}
          >
            <BodyText>Products</BodyText>
            <NextLink size="md" href={getPagePath({ page: PageId.Options })} label="Options" />
            <NextLink size="md" href={getPagePath({ page: PageId.Perps })} label="Perpetuals" />
            <NextLink size="md" href={getPagePath({ page: PageId.DRV })} label="Airdrop" />
            <NextLink size="md" href={getPagePath({ page: PageId.Stake })} label="LYRA Migration" />
            <Link size="md" href={DOCS_URL} target="_blank" label="Developers" />
            <Link
              size="md"
              label="Governance Portal"
              href={GOVERNANCE_PORTAL_URL}
              target="_blank"
            />
            <Link size="md" label="Delegate Portal" href={DELEGATE_PORTAL_URL} target="_blank" />
            <Link size="md" label="Forums" href={GOVERNANCE_FORUMS_URL} target="_blank" />
          </YStack>
          <YStack
            gap="$2"
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            $mobile={{ flexBasis: 'auto' }}
          >
            <BodyText>Support</BodyText>
            <ChatSupportLink />
            <Link size="md" label="Help Center" href={HELP_URL} target="_blank" />
            <Link size="md" label="Status" href={STATUS_URL} target="_blank" />
            <Link size="md" label="Fees" href={DOCS_FEES_URL} target="_blank" />
            <Link size="md" label="API Reference" href={DOCS_API_REFERENCE_URL} target="_blank" />

            <Link size="md" label="Snapshot" href={SNAPSHOT_URL} target="_blank" />
          </YStack>
          <Stack
            gap="$2"
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            $mobile={{ minWidth: '100%', flexShrink: 0 }}
          >
            <BodyText>Community</BodyText>
            <Stack
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 18px)',
                columnGap: '18px',
                rowGap: '18px',
              }}
            >
              <LinkIcon size={20} icon={<Twitter />} href={TWITTER_URL} target="_blank" />
              <LinkIcon size={20} icon={<Discord />} href={DISCORD_URL} target="_blank" />
              <LinkIcon size={20} icon={<Warpcast />} href={WARPCAST_URL} target="_blank" />
              <LinkIcon size={20} icon={<Github />} href={GITHUB_URL} target="_blank" />
              <LinkIcon size={20} icon={<Telegram />} href={TELEGRAM_URL} target="_blank" />
              <LinkIcon size={20} icon={<Youtube />} href={YOUTUBE_URL} target="_blank" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

LandingPageFooter.displayName = 'PageFooter'

export default LandingPageFooter
