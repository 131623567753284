import { AllInstrumentStatisticsSchema } from '@lyra/core/api/types/public.all_statistics'
import Card from '@lyra/core/components/Card'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import filterNulls from '@lyra/core/utils/filterNulls'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import formatTruncatedUSD from '@lyra/core/utils/formatTruncatedUSD'
import { InstrumentType } from '@lyra/web/constants/instruments'
import useAllMarketStats from '@lyra/web/hooks/useAllMarketStats'
import useTvl from '@lyra/web/hooks/useTvl'
import { StackProps, XStack } from 'tamagui'

const TOTAL_V1_NOTIONAL_VOLUME = 1_400_000_000
const TOTAL_V1_TRADES = 24_532

type Props = StackProps

export const LandingPageStats = ({ ...stackProps }: Props) => {
  const { data: marketStats, isLoading } = useAllMarketStats()
  const { data: tvl, isLoading: isTvlLoading } = useTvl()

  let totalVolume = 0
  let totalTrades = 0

  if (marketStats && !isLoading) {
    const sumNotionalVolume = (arr: AllInstrumentStatisticsSchema[]) =>
      arr.reduce((sum, stats) => sum + parseFloat(stats.total_notional_volume || '0'), 0)

    const sumTrades = (arr: AllInstrumentStatisticsSchema[]) =>
      arr.reduce((sum, stats) => sum + (stats.total_trades || 0), 0)

    totalVolume = sumNotionalVolume(
      Object.values(InstrumentType).flatMap((type) => filterNulls(Object.values(marketStats[type])))
    )

    totalTrades = sumTrades(
      Object.values(InstrumentType).flatMap((type) => filterNulls(Object.values(marketStats[type])))
    )
  }

  return (
    <XStack gap="$2" {...stackProps}>
      <Card flexGrow={1} flexShrink={1} flexBasis={0} maxWidth={140}>
        <DataWithLabel
          label="Volume"
          isEditorial
          value={
            !isLoading && marketStats
              ? formatTruncatedUSD(TOTAL_V1_NOTIONAL_VOLUME + totalVolume, { dps: 1 })
              : '...'
          }
        />
      </Card>
      <Card flexGrow={1} flexShrink={1} flexBasis={0} maxWidth={140}>
        <DataWithLabel
          label="TVL"
          isEditorial
          value={!isTvlLoading && tvl ? formatTruncatedUSD(tvl.tvl, { dps: 1 }) : '...'}
        />
      </Card>
      <Card flexGrow={1} flexShrink={1} flexBasis={0} maxWidth={140}>
        <DataWithLabel
          label="Trades"
          isEditorial
          value={
            !isLoading && marketStats ? formatTruncatedNumber(TOTAL_V1_TRADES + totalTrades) : '...'
          }
        />
      </Card>
    </XStack>
  )
}

export default LandingPageStats
