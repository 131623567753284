'use client'

import BodyText from '@lyra/core/components/BodyText'
import { ButtonColor } from '@lyra/core/components/Button'
import Heading from '@lyra/core/components/Heading'
import Responsive from '@lyra/core/components/Responsive'
import SpanText from '@lyra/core/components/SpanText'
import { TextColor } from '@lyra/core/utils/text'
import {
  LANDING_PAGE_WIDTH,
  NAV_MARQUEE_HEIGHT,
  NAV_MOBILE_TABS_HEIGHT,
} from '@lyra/web/constants/layout'
import DepositButton from '@lyra/web/containers/common/DepositButton'
import LandingPageStats from '@lyra/web/containers/landing/LandingPageStats'
import useNavHeight from '@lyra/web/hooks/useNavHeight'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { Stack, XStack, YStack } from 'tamagui'

const verbList: { verb: string; color: TextColor; buttonColor: ButtonColor }[] = [
  {
    verb: 'trading',
    color: 'red',
    buttonColor: 'red',
  },
  {
    verb: 'yield',
    color: 'green',
    buttonColor: 'green',
  },
  {
    verb: 'lending',
    color: 'amber',
    buttonColor: 'amber',
  },
  {
    verb: 'insurance',
    color: 'blue',
    buttonColor: 'blue',
  },
]

const glitchInterval = 25 // Glitch interval in milliseconds
const glitchDuration = 250 // Total glitch duration in milliseconds
const glitchChars = '0123456789!@#$%^&*()_+=-[]{}|;:,.<>?/'
const verbDuration = 3000

const getRandomChar = () => glitchChars[Math.floor(Math.random() * glitchChars.length)]

// Function to apply the glitch effect
const getRandomText = () => {
  const glitchedTextArray = 'xxxxxx'.split('').map(() => getRandomChar())
  return glitchedTextArray.join('')
}

const LandingHeroSection = () => {
  const [verbIndex, setVerbIndex] = useState(0)
  const [glitchText, setGlitchText] = useState('')
  const { mobileHeight, desktopHeight } = useNavHeight()
  const verbItem = verbList[verbIndex]

  useEffect(() => {
    const i = setInterval(() => {
      // delay 4s before next transition
      setVerbIndex((idx) => (idx === verbList.length - 1 ? 0 : idx + 1))

      // start glitch
      let elapsed = 0
      const intervalId = setInterval(() => {
        const text = getRandomText()
        setGlitchText(text)

        elapsed += glitchInterval

        // Stop the glitch effect after the total duration
        if (elapsed >= glitchDuration) {
          clearInterval(intervalId)
          setGlitchText('') // Revert to original text
        }
      }, glitchInterval)
    }, verbDuration)

    return () => clearInterval(i)
  }, [verbIndex])

  const headlineText = (
    <>
      Experience the most
      <br />
      powerful&nbsp;
      <SpanText color={verbItem.color}>{glitchText ? glitchText : verbItem.verb}</SpanText>
      <br />
      protocol in DeFi
    </>
  )

  const supportingCopyText = (
    <>
      Derive is a decentralized protocol that creates unique and programmable onchain options,
      perpetuals, and structured products.
    </>
  )

  const [gifSrc, setGifSrc] = useState<string>()

  useEffect(() => {
    const gif = new window.Image()
    gif.src = '/images/red-bg-1.gif'
    gif.onload = () => {
      setGifSrc(new URL(gif.src).pathname)
    }
  }, [])

  return (
    <XStack
      width="100%"
      $desktop={{
        minHeight: `min(1000px, calc(100vh - ${desktopHeight + NAV_MARQUEE_HEIGHT}px))`,
      }}
      $mobile={{
        height: `min(1000px, calc(100svh - ${mobileHeight + NAV_MARQUEE_HEIGHT}px))`,
        paddingBottom: NAV_MOBILE_TABS_HEIGHT,
      }}
      justifyContent="center"
      alignItems="center"
      paddingHorizontal="$3"
      overflow="hidden"
    >
      <Stack
        position="absolute"
        top={0}
        right={0}
        width="100%"
        height="100%"
        $desktop={{
          opacity: gifSrc ? 1 : 0,
        }}
        $mobile={{
          opacity: gifSrc ? 0.5 : 0,
        }}
        style={{
          transition: 'all 500ms ease',
        }}
      >
        {gifSrc ? (
          <Image
            priority
            alt="red-dots"
            unoptimized
            src={gifSrc}
            fill
            style={{
              objectFit: 'cover',
              objectPosition: 'right',
            }}
          />
        ) : null}
      </Stack>
      <YStack
        width="100%"
        height="100%"
        $desktop={{
          justifyContent: 'center',
          paddingVertical: '$8',
          gap: '$6',
          maxWidth: LANDING_PAGE_WIDTH,
          marginBottom: NAV_MARQUEE_HEIGHT,
        }}
        $mobile={{
          gap: '$6',
          paddingVertical: '$4',
          justifyContent: 'center',
        }}
      >
        <YStack gap="$1" maxWidth={600} width="100%">
          <Responsive
            desktop={<Heading size="h1">{headlineText}</Heading>}
            mobile={<Heading size="h2">{headlineText}</Heading>}
          />
          <Responsive
            desktop={
              <BodyText size="editorial" color="secondary">
                {supportingCopyText}
              </BodyText>
            }
            mobile={
              <BodyText size="lg" color="secondary">
                {supportingCopyText}
              </BodyText>
            }
          />
        </YStack>
        <YStack gap="$1" justifyContent="center" width="fit-content">
          <BodyText color="secondary" size="lg">
            Deposit to start earning $DRV
          </BodyText>
          <DepositButton
            label="Deposit Now"
            width={160}
            // disable default CTA
            color={verbItem.buttonColor}
            isCta={false}
            size="lg"
            isSolid
          />
        </YStack>
        <XStack>
          <LandingPageStats width="100%" />
        </XStack>
      </YStack>
    </XStack>
  )
}

export default LandingHeroSection
